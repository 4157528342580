import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Card, Table, Input,
  Tooltip,
} from 'antd';
import { CloseSquareOutlined } from '@ant-design/icons';
import PlantMaterialSelector from '../massUpdateMrp/plantMaterialSelecor/PlantMaterialSelector';
import { updateSelectedMaterials } from '../utils';
import allActions from '../../../../actions';
import styles from './styles.module.scss';
import { getCorrectTokenFromUser } from '../../../../utils/token';
import { massUpdate } from '../massUpdateGlobals';
import ConfirmModal from '../../../Common/ConfirmModal';
import { formatMaterialNumber } from '../../../Common/DateAndNumberFunctions';
import FixedBinConfirm from './FixedBinConfirm';
import { showNotification } from '../../../../actions/utilities/Notifications/NotificationActions';

const MassUpdateFixedBin = ({ matIds, currentTab }) => {
  if (currentTab !== massUpdate.FIXED_BIN) return null;
  const user = useSelector((state) => state.authState.user);
  const [plant, setPlant] = useState();
  const [selectedMaterials, setSelectedMaterials] = useState([]);

  const [warehouseNumber, setWarehouseNumber] = useState();

  const [fixedBinData, setFixedBinData] = useState([]);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changes, setChanges] = useState({});
  const [populateAll, setPopulateAll] = useState({});
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const validStorageTypes = ['001', '002', '003', '020'];

  useEffect(() => {
    if (matIds?.ids?.length > 0) {
      if (matIds.plant) setPlant(matIds.plant?.toString());
      updateSelectedMaterials(matIds.ids, setSelectedMaterials, dispatch);
    }
  }, []);

  const updateSelected = (selectedMats) => {
    updateSelectedMaterials(selectedMats, setSelectedMaterials, dispatch);
  };

  const resetAll = (includePlantMaterials = true) => {
    if (includePlantMaterials) {
      setPlant();
      setSelectedMaterials([]);
    }
    setError(false);
  };

  const createCorrectDataStructure = (mrpData, materialData, whs, fixedBins) => {
    const temp = [];
    mrpData.forEach((mrp) => {
      const findMaterial = materialData.find((v) => v.MATERIAL_TYPE_SAP_ID === formatMaterialNumber(mrp.MaterialNumber));
      if (findMaterial) {
        const hasStorageValues = {
          '001': 'NO_VALUE',
          '002': 'NO_VALUE',
          '003': 'NO_VALUE',
          '020': 'NO_VALUE',
        };

        const newObject = {
          plant,
          material: findMaterial.MATERIAL_TYPE_SAP_ID,
          mrpType: mrp.MRPType,
          materialDescription: findMaterial.EDAM_MATERIAL_TYPE_NM,
          whs,
          ...hasStorageValues,
        };
        fixedBins.forEach((fb) => {
          if (fb.MaterialNumber === mrp.MaterialNumber) {
            newObject[fb.StorageType] = fb.StorageBin;
          }
        });

        temp.push(newObject);
      }
    });
    setFixedBinData(temp);
    setLoading(false);
  };

  const createChunks = (list) => {
    const chunkSize = 150;
    const arrayOfArrays = [];

    const arrayToUse = list || selectedMaterials;

    for (let i = 0; i < arrayToUse.length; i += chunkSize) {
      const chunk = arrayToUse.slice(i, i + chunkSize);
      arrayOfArrays.push(chunk);
    }
    return arrayOfArrays;
  };

  const getMrpData = async () => {
    const mrpData = [];
    if (selectedMaterials.length > 150) {
      const arrayOfArrays = createChunks();

      await Promise.all(arrayOfArrays.map(async (arr) => {
        const data = await allActions.SapAPIActions.getMRPsWithFetch([plant], arr, getCorrectTokenFromUser(user), dispatch);
        mrpData.push(...data);
      }));
    } else {
      const data = await allActions.SapAPIActions.getMRPsWithFetch([plant], selectedMaterials, getCorrectTokenFromUser(user), dispatch);
      mrpData.push(...data);
    }

    return mrpData;
  };

  const getFixedBinData = async (whs) => {
    const fixedBinData = [];
    if (selectedMaterials.length > 150) {
      const arrayOfArrays = createChunks();

      await Promise.all(arrayOfArrays.map(async (arr) => {
        const data = await allActions.SapAPIActions.getFixedBin(arr.join(','), whs, getCorrectTokenFromUser(user), dispatch);
        fixedBinData.push(...data);
      }));
    } else {
      const data = await allActions.SapAPIActions.getFixedBin(selectedMaterials.join(','), whs, getCorrectTokenFromUser(user), dispatch);
      fixedBinData.push(...data);
    }

    return fixedBinData;
  };

  const fetchAllRelatedData = async () => {
    if (!plant || !(selectedMaterials?.length > 0)) return;
    setLoading(true);
    const plantMaterials = selectedMaterials.map((materialID) => ({
      plantID: plant,
      materialID,
    }));
    const mrpData = await getMrpData();
    const matData = await allActions.MaterialDetailsActions.fetchMaterialsAsync(plantMaterials, getCorrectTokenFromUser(user), dispatch);
    const fetchWarehouseData = await allActions.MaterialDetailsActions.fetchWarehouseByPlant(plant, getCorrectTokenFromUser(user), dispatch);
    const fixedBins = await getFixedBinData(fetchWarehouseData.WAREHOUSE_NUMBER);
    setWarehouseNumber(fetchWarehouseData.WAREHOUSE_NUMBER);
    createCorrectDataStructure(mrpData, matData, fetchWarehouseData.WAREHOUSE_NUMBER, fixedBins);
  };

  const getRowClassName = (record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark');

  const updateOriginalValues = () => {
    const temp = [...fixedBinData];
    temp.forEach((fb) => {
      validStorageTypes.forEach((valid) => {
        if (changes[`${fb.material}-${valid}`]) {
          fb[valid] = changes[`${fb.material}-${valid}`].fixedBin;
        }
      });
    });
    setFixedBinData(temp);
    setChanges({});
  };

  const submitChanges = async () => {
    setLoading(true);
    setShowModal(false);
    const dataToSend = {
      MrpBatch: '123',
      to_WarehouseManagement: [

      ],
    };
    Object.values(changes).forEach((change) => {
      dataToSend.to_WarehouseManagement.push({
        MaterialNumber: change.material,
        WarehouseNumber: warehouseNumber,
        StorageType: change.storageType,
        StorageBin: change.fixedBin ? change.fixedBin.toUpperCase() : '',
        StorageBin_X: true,
      });
    });

    const errors = await allActions.SapAPIActions.setMrpsAsync(dataToSend, getCorrectTokenFromUser(user), dispatch, true, plant);
    if (!errors) {
      updateOriginalValues();
      dispatch(showNotification({
        Type: 'success',
        Title: 'SAP update successful',
        Message: `Fixed Bins for warehouse ${warehouseNumber} updated`,
      }));
    }
    setLoading(false);
  };

  const updateChanges = (value, material, storType) => {
    const oldData = fixedBinData.find((m) => m.material === material);
    if (oldData) {
      if (oldData[storType] === value) {
        setChanges((prev) => {
          delete prev[`${material}-${storType}`];
          return {
            ...prev,
          };
        });
      } else {
        setChanges((prev) => ({
          ...prev,
          [`${material}-${storType}`]: {
            fixedBin: value,
            material,
            storageType: storType,
          },
        }));
      }
    }
  };

  const populateAllValues = () => {
    Object.entries(populateAll).forEach((v) => {
      fixedBinData.forEach((fb) => {
        if (fb[v[0]] !== 'NO_VALUE') {
          updateChanges(v[1], fb.material, v[0]);
        }
      });
    });
  };

  const clearAllFixedBins = () => {
    fixedBinData.forEach((fb) => {
      validStorageTypes.forEach((validNr) => {
        if (fb[validNr] !== 'NO_VALUE') {
          updateChanges('', fb.material, validNr);
        }
      });
    });
  };

  const clearAllNDMaterials = () => {
    fixedBinData.forEach((fb) => {
      if (fb.mrpType === 'ND') {
        validStorageTypes.forEach((validNr) => {
          if (fb[validNr] !== 'NO_VALUE') {
            updateChanges('', fb.material, validNr);
          }
        });
      }
    });
  };

  const clearAllForStorType = (storType) => {
    fixedBinData.forEach((fb) => {
      if (fb[storType] !== 'NO_VALUE') updateChanges('', fb.material, storType);
    });
  };

  const columns = [
    {
      children: [{
        title: 'Plant',
        dataIndex: 'plant',
        render: () => plant,
        width: '5%',
      }],
    },
    {
      children: [{
        title: 'Whs',
        render: (v) => v.whs,
        width: '5%',
      }],
    },
    {
      children: [{
        title: 'Material',
        dataIndex: 'material',
        render: (material) => material,
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.material - b.material,
        width: '8%',
      }],
    },
    {
      children: [{

        title: 'Material description',
        render: (v) => v.materialDescription,
        width: '25%',

      }],
    },
    {
      children: [{

        title: 'MRP Type',
        render: (v) => v.mrpType,
        width: '8%',
        sorter: (a, b) => {
          if (a.mrpType > b.mrpType) return -1;
          if (a.mrpType < b.mrpType) return 1;
          return 0;
        },
      }],
    },
    {
      title: () => (
        <div className={styles.headerInput}>
          <Input
            onChange={(e) => {
              const { value } = e.target;
              setPopulateAll((prev) => ({
                ...prev,
                '001': value,
              }));
            }}
            maxLength={10}
            placeholder="Input"
            className={styles.populateInputs}
            disabled={fixedBinData.length === 0}
            value={populateAll['001']}
          />
          <Tooltip title="Clear all 001 storage bins"><CloseSquareOutlined className={`${styles.clearIcon} ${fixedBinData.length > 0 ? styles.activeIcon : {}}`} onClick={() => (fixedBinData.length > 0 ? clearAllForStorType('001') : null)} /></Tooltip>
        </div>
      ),
      children: [{
        title: 'Stor.Type 001',
        render: (v) => {
          const valueToUse = () => {
            if (changes[`${v.material}-001`]) {
              return changes[`${v.material}-001`].fixedBin;
            } if (v['001'] !== 'NO_VALUE') return v['001'];
            return '';
          };
          return (
            <div>
              <Input
                onChange={(e) => updateChanges(e?.target?.value, v.material, '001')}
                maxLength={10}
                disabled={v['001'] === 'NO_VALUE'}
                value={valueToUse()}
              />
            </div>
          );
        },
        width: '10%',
      }],
    },
    {
      title: () => (
        <div className={styles.headerInput}>
          <Input
            onChange={(e) => {
              const { value } = e.target;
              setPopulateAll((prev) => ({
                ...prev,
                '002': value,
              }));
            }}
            maxLength={10}
            placeholder="Input"
            className={styles.populateInputs}
            disabled={fixedBinData.length === 0}
            value={populateAll['002']}
          />
          <Tooltip title="Clear all 002 storage bins"><CloseSquareOutlined className={`${styles.clearIcon} ${fixedBinData.length > 0 ? styles.activeIcon : {}}`} onClick={() => (fixedBinData.length > 0 ? clearAllForStorType('002') : null)} /></Tooltip>
        </div>
      ),
      children: [{
        title: 'Stor.Type 002',
        render: (v) => {
          const valueToUse = () => {
            if (changes[`${v.material}-002`]) {
              return changes[`${v.material}-002`].fixedBin;
            } if (v['002'] !== 'NO_VALUE') return v['002'];
            return '';
          };
          return (
            <div>
              <Input
                onChange={(e) => updateChanges(e?.target?.value, v.material, '002')}
                maxLength={10}
                disabled={v['002'] === 'NO_VALUE'}
                value={valueToUse()}
              />
            </div>
          );
        },
        width: '10%',

      }],
    },
    {
      title: () => (
        <div className={styles.headerInput}>
          <Input
            onChange={(e) => {
              const { value } = e.target;
              setPopulateAll((prev) => ({
                ...prev,
                '003': value,
              }));
            }}
            maxLength={10}
            placeholder="Input"
            className={styles.populateInputs}
            disabled={fixedBinData.length === 0}
            value={populateAll['003']}
          />
          <Tooltip title="Clear all 003 storage bins"><CloseSquareOutlined className={`${styles.clearIcon} ${fixedBinData.length > 0 ? styles.activeIcon : {}}`} onClick={() => (fixedBinData.length > 0 ? clearAllForStorType('003') : null)} /></Tooltip>
        </div>
      ),
      children: [{
        title: 'Stor.Type 003',
        render: (v) => {
          const valueToUse = () => {
            if (changes[`${v.material}-003`]) {
              return changes[`${v.material}-003`].fixedBin;
            } if (v['003'] !== 'NO_VALUE') return v['003'];
            return '';
          };
          return (
            <div>
              <Input
                onChange={(e) => updateChanges(e?.target?.value, v.material, '003')}
                maxLength={10}
                disabled={v['003'] === 'NO_VALUE'}
                value={valueToUse()}
              />
            </div>
          );
        },
        width: '10%',
      }],
    },
    {
      title: () => (
        <div className={styles.headerInput}>
          <Input
            onChange={(e) => {
              const { value } = e.target;
              setPopulateAll((prev) => ({
                ...prev,
                '020': value,
              }));
            }}
            maxLength={10}
            placeholder="Input"
            className={styles.populateInputs}
            disabled={fixedBinData.length === 0}
            value={populateAll['020']}
          />
          <Tooltip title="Clear all 020 storage bins"><CloseSquareOutlined className={`${styles.clearIcon} ${fixedBinData.length > 0 ? styles.activeIcon : {}}`} onClick={() => (fixedBinData.length > 0 ? clearAllForStorType('020') : null)} /></Tooltip>
        </div>
      ),
      children: [{
        title: 'Stor.Type 020',
        render: (v) => {
          const valueToUse = () => {
            if (changes[`${v.material}-020`]) {
              return changes[`${v.material}-020`].fixedBin;
            } if (v['020'] !== 'NO_VALUE') return v['020'];
            return '';
          };
          return (
            <div>
              <Input
                onChange={(e) => updateChanges(e?.target?.value, v.material, '020')}
                maxLength={10}
                disabled={v['020'] === 'NO_VALUE'}
                value={valueToUse()}
              />
            </div>
          );
        },
        width: '10%',
      }],
    },

  ];

  return (
    <Card>
      <ConfirmModal
        style={{
          minWidth: '80vw', minHeight: '80vh', maxWidth: '80vw', maxHeight: '80vh',
        }}
        title="Confirm changes"
        visible={showModal}
        onOK={submitChanges}
        onCancel={() => {
          setShowModal(false);
        }}
        message={(
          <FixedBinConfirm
            plant={plant}
            originalValues={fixedBinData}
            changes={changes}
            key={fixedBinData}
            warehouse={warehouseNumber}
          />
        )}
      />
      <div className={styles.fixedBinLayout}>
        <PlantMaterialSelector
          plant={plant}
          updatePlant={(p) => setPlant(p)}
          materials={selectedMaterials}
          setMaterials={updateSelected}
          onOK={fetchAllRelatedData}
          error={error}
          setError={setError}
          resetAll={resetAll}
          validMaterials={selectedMaterials}
        />
        <Table
          className={styles.fixedBinTable}
          rowClassName={getRowClassName}
          columns={columns}
          showSorterTooltip={false}
          dataSource={fixedBinData}
          pagination={false}
          size="small"
          scroll={{ x: 1500, y: 398 }}
          loading={loading}
          footer={() => (
            <div className={styles.buttons}>
              <>
                <Button disabled={fixedBinData.length === 0} onClick={() => { clearAllFixedBins(); }}>Clear all</Button>
                <Button disabled={fixedBinData.length === 0} onClick={() => { clearAllNDMaterials(); }}>Clear for ND materials</Button>
                <Button disabled={fixedBinData.length === 0 || Object.keys(populateAll).length === 0} onClick={() => { populateAllValues(); }}>Populate inputs</Button>
                <Button
                  disabled={Object.keys(changes).length === 0 || fixedBinData.length === 0}
                  onClick={() => {
                    setPopulateAll({});
                    setChanges({});
                  }}
                >
                  Reset all changes
                </Button>
                <Button disabled={Object.keys(changes).length === 0 || fixedBinData.length === 0} type="primary" onClick={() => { setShowModal(true); }}>Submit</Button>
              </>
            </div>
          )}
        />
      </div>
    </Card>
  );
};

export default MassUpdateFixedBin;
